import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'

const getEmail = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'email',
    method: 'get'
  })

  return data
}
const putEmail = async ({ email }) => {
  const payload = { email }

  const { data } = await defaultLeadsApiCall({
    endpoint: 'email',
    method: 'put',
    body: payload
  })

  return data
}

export default { getEmail, putEmail }

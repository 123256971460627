import { useState, useEffect } from 'react'

import { useLead } from '@itau-loans-www/shopping/src/hooks'
import analytics from '@mobi/libraries/analytics'
import { goTo } from 'utils'

import { DDPageError } from 'utils/datadog/events'

import services from './services'

const useEmail = () => {
  const [isButtonLoading, setButtonLoading] = useState(false)
  const { changeLeadData, leadData } = useLead()

  useEffect(() => {
    services
      .getEmail()
      .then((data) => {
        changeLeadData({ previousStep: data?.previous_step })
      })
      .catch((err) => {
        const errorPayload = {
          error: err?.message,
          requestURL: err?.request?.responseURL
        }

        DDPageError({
          name: 'useEmail | getEmail',
          payload: errorPayload
        })
      })

    analytics.track('pageLoad', {
      currentPage: 'formulario-dados-complementares-email',
      customLayer: { variant: leadData?.vwoVariant }
    })
  }, [])

  const onSubmit = async ({ email }) => {
    setButtonLoading(true)

    analytics.track('buttonClick', {
      currentPage: 'formulario-dados-complementares-email',
      detail: 'click-step-basic'
    })

    return services
      .putEmail({ email })
      .then(({ next_step }) => {
        changeLeadData({
          email
        })
        goTo(next_step)
      })
      .catch((err) => {
        const errorPayload = {
          error: err?.message,
          requestURL: err?.request?.responseURL
        }

        DDPageError({
          name: 'useEmail | onSubmit',
          payload: errorPayload
        })
      })
      .finally(() => setButtonLoading(false))
  }

  return {
    onSubmit,
    isButtonLoading
  }
}

export default useEmail
